@import "../../../commonStyles.module.scss";

$fileExplorerWidth: 335px;

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
.main {
  height: 100px;
  flex-grow: 1;
  background-color: #fcfcfc;
}
.search {
  background-color: #eee;
  border-bottom: 1px solid #e0e0e0;
}
.mainContent,
.searchContent {
  @extend .portal-content;
}
.mainContent {
  height: 100%;
  position: relative;
  .appContent,
  .resizeBar,
  .fileExplorer {
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .fileExplorer {
    left: 0px;
    width: $fileExplorerWidth;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #e0e0e0;
  }
  .resizeBar {
    width: 3px;
    left: $fileExplorerWidth;
    background-color: transparent;
    z-index: 10;
  }
  .resizeBar:hover {
    cursor: col-resize;
  }
  .appContent {
    right: 0px;
    left: $fileExplorerWidth;
    overflow-x: auto;
    overflow-y: auto;
  }
}
