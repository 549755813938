.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 4px 0px;
  align-items: baseline;
  .selectedItems,
  .selectedTexts {
    padding-right: 4px;
  }
  .selectedItems {
    list-style-type: square;
    margin-block: 0px;
    padding-inline-start: 18px;
  }
}
.dialog {
  .listActionPane {
    font-size: 12px;
    margin-top: -6px;
    margin-bottom: 6px;
    :global(.ms-Link) {
      padding-right: 10px;
    }
  }
  .itemListPane {
    height: 60vh;
    overflow: auto;
    margin-bottom: 20px;
    border: 1px solid #eee;
    .itemList {
      .item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 2px 10px;
        .actionPane {
          display: none;
        }
      }
      .item:hover {
        background-color: #f0f8ff;
        .actionPane {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.actionIcon {
  font-size: 12px;
  padding: 4px;
  border-radius: 2px;
}
.actionIcon:hover {
  cursor: pointer;
  color: white;
  background-color: #0078d4;
}
