@import "../../shared/styles/Search.module.scss";
@import "../../shared/styles/SelectedContentHeader.module.scss";

.actionPane {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.root {
  margin: 20px 10px;
  padding: 20px;
}

.contentPane {
  margin: 20px 10px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 18%), 0 0.3px 0.9px 0 rgb(0 0 0 / 15%);
  :global(.ms-DetailsHeader) {
    background-color: #f0f0f0;
  }
}

.noContentPane {
  padding: 50px;
  .noContentText {
    padding-bottom: 50px;
    padding-top: 20px;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.header {
  padding-top: 12px;
  padding-left: 15px;
  .headerItem {
    font-size: 18px;
    .headerText {
      max-width: 300px;
      min-width: 30px;
    }
    .headerIcon {
      font-size: 12px;
    }
    .lastHeaderText {
      font-weight: 400;
      max-width: 700px;
      min-width: 100px;
      font-size: 15px;
    }
  }
}
