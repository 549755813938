.root {
  padding: 20px;
  .userPane {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    .userImage {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
    .userInfo {
      text-align: left;
      padding-left: 10px;
      .userPrincipalName {
        font-size: 12px;
      }
    }
    .userIcon {
      padding-right: 8px;
    }
  }
  .settingsPane,
  .userPane {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .settingsPane,
  .logoutPane {
    padding-top: 20px;
  }
  .logoutPane {
    text-align: center;
  }
}
