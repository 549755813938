.searchPane {
  background-color: #f0f0f0;
  padding: 10px;
}
.searchResultPane {
  border: 1px solid #f0f0f0;
  overflow: auto;
  max-height: 40vh;
  :global(.ms-DetailsHeader) {
    background-color: #fafafa;
  }
  :global(.ms-DetailsHeader-cellName) {
    font-size: 12px;
    font-weight: 400;
  }
}
.spinner {
  padding: 20px;
}
.searchCount {
  font-size: 12px;
  font-weight: 300;
  padding-top: 8px;
}
