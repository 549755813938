$smallScreenWidth: 1024px;
.root {
  padding: 0px;
  overflow-y: auto;
  height: 100%;
}
.commandBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  padding-left: 10px;
}
.iconButton {
  padding: 8px;
  font-size: 12px;
}
.noneFound {
  padding: 20px;
}
.spinner {
  padding: 20px;
}
.navLinkPane {
  width: 100%;
  text-align: left;
  position: relative;
  .statusHint {
    position: absolute;
    display: absolute;
    top: 2px;
    left: -8px;
    font-weight: 800;
    font-style: italic;
  }
  .navLink {
    padding-left: 2px;
    font-weight: 300;
  }
  .navLinkSelected {
    background-color: #eff6fc;
    font-weight: 600;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    width: 240px;
    min-width: 240px;
  }
}
