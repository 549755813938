@import "../../shared/styles/Search.module.scss";
.callout {
  min-width: 220px;
  max-width: 400px;
  margin-left: -10px;
}
.autoCompleteItem {
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  .inactiveLabel {
    padding-left: 6px;
    font-size: 10px;
    color: red;
    align-self: center;
  }
}
.autoCompleteItem:hover {
  background-color: #eee;
  cursor: pointer;
}
.autoCompleteItemType,
.autoCompleteItemValue {
  display: inline-block;
}
.autoCompleteItemType::after {
  content: ":";
}
.autoCompleteItemType {
  font-weight: 300;
}
.autoCompleteItemValue {
  padding-left: 6px;
  font-weight: 500;
}
.inactive {
  font-weight: 300;
}
