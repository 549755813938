.displayField,
.targetDisplayField {
  font-weight: 600;
}
.targetDisplayField {
  color: #0078d4;
}
.infoPane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .searchBox {
    margin-top: 2px;
    margin-right: 10px;
    width: 160px;
    align-self: flex-end;
  }
}
.textPane {
  padding: 10px;
}
.fileErrorsPane {
  .fileErrorsTable {
    margin-top: 10px;
    position: relative;
    overflow: auto;
    .targetColumn {
      color: red;
      font-weight: 600;
      padding: 0px 6px;
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
    .fixNotNeeded {
      color: black;
      font-weight: 400;
      padding-left: 6px;
    }
    .valueChangedColumn,
    .valueChangedColumn.force,
    .valueChangedColumn.keepAsIs,
    .valueChangedColumn.unfixable {
      color: green;
    }
    .valueChangedColumn.fileFixLevel {
      color: #0078d4;
    }
    .valueChangedColumn.sourceFixLevel {
      color: darkmagenta;
    }
    .valueChangedColumn::before,
    .valueChangedColumn.force::before,
    .valueChangedColumn.keepAsIs::before,
    .valueChangedColumn.fileFixLevel::before,
    .valueChangedColumn.sourceFixLevel::before,
    .valueChangedColumn.unfixable::before {
      content: "R";
      padding: 2px;
      margin-right: 4px;
      color: white;
      background-color: green;
      border-radius: 16px;
      width: 16px;
      min-width: 16px;
      text-align: center;
    }
    .valueChangedColumn.sourceFixLevel::before {
      content: "S";
      background-color: darkmagenta;
    }
    .valueChangedColumn.fileFixLevel::before {
      content: "F";
      background-color: #0078d4;
    }
    .valueChangedColumn.force::before {
      content: "f";
    }
    .valueChangedColumn.keepAsIs::before {
      content: "k";
    }
    .valueChangedColumn.unfixable::before {
      content: "U";
    }
    .valueChangedColumn.selected::before,
    .valueChangedColumn.force.selected::before,
    .valueChangedColumn.keepAsIs.selected::before,
    .valueChangedColumn.fileFixLevel.selected::before,
    .valueChangedColumn.sourceFixLevel.selected::before,
    .valueChangedColumn.unfixable.selected::before {
      border: 2px dotted #aaa;
      padding: 0px;
    }
    .valueChangedColumn.matchedToSku::after,
    .valueChangedColumn.matchedToUpc::after,
    .valueChangedColumn.matchedToProductId::after {
      font-size: 9px;
    }
    .valueChangedColumn.matchedToSku::after {
      content: "(SKU)";
    }
    .valueChangedColumn.matchedToUpc::after {
      content: "(UPC)";
    }
    .valueChangedColumn.matchedToProductId::after {
      content: "(PID)";
    }
    .errorColumnHeader {
      :global(.ms-DetailsHeader-cellName) {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .targetColumnHeader {
      :global(.ms-DetailsHeader-cellName) {
        font-size: 12px;
      }
      color: red;
    }
    .valueChangedItemPane {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .valueChangedTextbox {
        margin: 0px;
        width: 100%;
      }
      .valueChangedDropdown {
        width: 80px;
      }
      .valueChangedEditField {
        padding: 0px 6px;
        flex-grow: 1;
      }
      .valueChangedText {
        background-color: transparent;
        border-width: 0px;
        color: green;
        font-weight: 600;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
  .itemCellPane {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .itemActionsPane {
    padding-left: 4px;
  }
  .valueChangedActionsIcon,
  .errorEditButton {
    margin-right: 4px;
    padding: 4px;
    border-radius: 2px;
    height: 12px;
    color: black;
    background-color: #ddd;
  }
  .valueChangedActionsIcon:hover,
  .errorEditButton:hover {
    color: white;
    background-color: #0078d4;
    cursor: pointer;
  }
}
.errorDialog {
  .recordInfoPane {
    margin: -10px -10px 0px -10px;
    .targetColumnField,
    .columnField {
      font-weight: 600;
    }
    .targetColumnField {
      color: red;
    }
    :global(.ms-Dropdown-caretDownWrapper),
    :global(.ms-Dropdown-title) {
      font-size: 12px;
      height: 28px;
      line-height: 24px;
    }
  }
  .searchPane {
    margin-top: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 2px;
    .searchFieldsPane {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .searchButton {
        margin-bottom: 4px;
      }
    }
    .searchInfoPane {
      margin: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 12px;
      .returnedCount,
      .productsCount {
        display: inline-block;
        margin-right: 20px;
      }
      .searchBox {
        width: 200px;
      }
    }
  }
  .searchResultPane {
    border: 1px solid #f0f0f0;
    overflow: auto;
    max-height: 30vh;
    :global(.ms-DetailsHeader) {
      background-color: #fafafa;
    }
    :global(.ms-DetailsHeader-cellName) {
      font-size: 12px;
      font-weight: 400;
    }
    .spinner {
      padding: 20px;
    }
    .targetColumnField {
      font-weight: 700;
      color: #0078d4;
    }
  }
}
.recordDetailsPane {
  height: 60vh;
  max-height: 60vh;
  max-width: 480px;
  overflow: auto;
  .list {
    display: table;
    font-size: 12px;
    .field {
      display: table-row;
      .name,
      .value {
        display: table-cell;
        padding: 0px 20px 6px 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .name {
        font-weight: 600;
      }
      .value {
        color: #444;
      }
    }
  }
}
