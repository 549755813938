.root {
  position: relative;
  height: 100%;
  border-left: 1px solid #e0e0e0;
  background-color: white;
  padding: 0px 20px;
  .headerTitle {
    font-weight: 500;
    color: #999;
  }
  .closeButton {
    position: absolute;
    top: 12px;
    right: 10px;
  }
  .logList {
    position: absolute;
    top: 50px;
    bottom: 0px;
    left: 20px;
    right: 0px;
    font-size: 12px;
    font-weight: 300;
    list-style: none;
    padding: 0px;
    overflow: auto;
    white-space: pre-wrap;
    .logItem {
      border-bottom: 1px solid #e8e8e8;
      padding: 10px 0px;
    }
  }
}
