@import "../../shared/styles/Search.module.scss";
@import "../../shared/styles/FilterInfo.module.scss";

.root {
  min-height: 48px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.searchMainPane {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px 0px;
}
.searchRoot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dateRangePicker,
.searchFiltersButton {
  margin-right: 2px;
}
.searchInfoButton,
.clearFiltersButton {
  margin-right: 6px;
}
.clearFiltersButton:hover,
.filterItemIcon:hover,
.searchFiltersButton:hover,
.buttonSelected {
  color: white;
  background-color: #0078d4;
  cursor: pointer;
}
.searchFilterIcon {
  padding-left: 10px;
}
.modeToggle {
  padding-right: 10px;
}
.searchInfoButtonContent {
  padding: 20px;
}
.dateFlag {
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.searchWarningCallout {
  padding: 10px 20px;
  width: 240px;
  background-color: white;
}
.loadingSpinner {
  padding-left: 4px;
}
.commandBar {
  :global(.ms-CommandBar-overflowButton) {
    background-color: #eee;
  }
}
