.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  .headerChevron {
    padding-right: 10px;
    font-size: 20px;
    color: #888;
  }
  .headerItemPane {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
  }
  .headerItem {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    margin-bottom: 20px;
    .headerKey,
    .headerText {
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: center;
      white-space: nowrap;
    }
    .headerIcon {
      font-weight: 200;
      padding-right: 6px;
    }
    .headerKey {
      position: absolute;
      top: -18px;
      left: 28px;
      font-size: 12px;
    }
    .headerTextPane {
      display: flex;
      align-items: baseline;
      font-weight: 300;
    }
  }
}
