@import "../../shared/styles/ContentHeader.module.scss";
.root {
}
.displayField {
  font-weight: 500;
}
.section {
  background-color: white;
  padding: 20px 30px;
  margin: 20px 10px;
  border-radius: 2px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}
.infoSection {
  margin: 0px;
  margin-bottom: 20px;
}
.infoItemNote {
  padding-top: 6px;
  padding-left: 10px;
}
.rejectStatus,
.readyStatus,
.otherStatus,
.purgedStatus {
  background-color: green;
  color: white;
  padding: 0px 6px;
  border-radius: 3px;
  font-size: 12px;
}
.readyStatus {
  background-color: green;
}
.rejectStatus {
  background-color: red;
}
.purgedStatus {
  background-color: purple;
}
.otherStatus {
  background-color: goldenrod;
}
.errorEditIcon {
  color: #0078d4;
  padding: 4px;
  border-radius: 2px;
}
.errorEditIcon:hover {
  color: white;
  background-color: #0078d4;
  cursor: pointer;
}
:global(.ms-DetailsHeader) {
  padding-top: 0px;
}
