.appRoot {
  position: relative;
  height: 100%;
  .content,
  .resizeBar,
  .logsPanel {
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .content {
    left: 0px;
    width: 100%;
  }
  .logsPanel {
    left: 1600px;
    right: 0px;
  }
  .resizeBar {
    left: 1600px;
    width: 3px;
    background-color: transparent;
    z-index: 10px;
  }
  .resizeBar:hover {
    cursor: col-resize;
  }
}
.userOptions {
  .actionLink {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    padding: 20px 0px 0px 0px;
  }
  .actionLink:hover {
    color: #0078d4;
    cursor: pointer;
    text-decoration: underline;
  }
}
.listPane {
  overflow: auto;
  max-height: 60vh;
}
.searchBox {
  width: 300px;
}
.infoText {
  padding-top: 20px;
}
.searchForm {
  background-color: #f0f0f0;
  padding-bottom: 10px;
}
.spinner {
  padding-top: 20px;
}
