.header {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 6px;
  min-width: 300px;
}

.headerPane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
