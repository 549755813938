.searchBox {
  width: 220px;
}
.searchButton {
  margin-left: 10px;
  margin-right: 10px;
}
.searchInfoPane {
  .searchInfoHeader {
    padding-bottom: 10px;
    font-weight: 500;
  }
  .searchInfoItem {
    padding: 2px 0px;
    display: flex;
    flex-direction: row;
    .searchInfoItemLabel {
      width: 30px;
      font-weight: 500;
    }
    .searchInfoItemExample {
      font-weight: 300;
      padding-left: 6px;
    }
    .searchInfoItemNote::after {
      content: ", e.g. ";
    }
  }
}
