@import "../../shared/styles/FileExplorer.module.scss";

.root {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: hidden;
  .commandBar,
  .fileList,
  .sourcesList,
  .templatesList {
    position: absolute;
    left: 0px;
    right: 0px;
  }
  .commandBar {
    top: 0px;
    height: 32px;
  }
  .fileActionsCommandBar {
    margin-right: 10px;
    .commandBarSpinner {
      width: 32px;
    }
  }
  .fileList,
  .templatesList,
  .sourcesList {
    top: 33px;
    bottom: 0px;
    overflow-y: auto;
  }
  .templatesList,
  .sourcesList {
    font-size: 12px;
    .template,
    .source {
      padding: 3px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .template:hover,
    .source:hover {
      cursor: pointer;
      background-color: #eee;
    }
    .template.selected,
    .source.selected {
      background-color: #eff6fc;
      font-weight: 600;
    }
    .source.inactive::after {
      content: " - inactive";
      color: red;
    }
  }
}
