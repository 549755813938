.root {
  margin: 20px 10px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 18%), 0 0.3px 0.9px 0 rgb(0 0 0 / 15%);
  :global(.ms-DetailsHeader) {
    background-color: #f0f0f0;
  }
}
.root.compact {
  :global(.ms-Dropdown-caretDownWrapper),
  :global(.ms-Dropdown-title) {
    font-size: 12px;
    height: 28px;
    line-height: 24px;
  }
}
.nonEditable {
  background-color: white;
}
.header {
  padding: 0px 10px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.actionPane {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.isSavingSpinner,
.button {
  margin-left: 10px;
}
.isSavingSpinner {
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: center;
}
.title {
  font-weight: 600;
  font-size: 18px;
}
