.resultPane {
  border: 1px solid #f0f0f0;
  overflow: auto;
  max-height: 40vh;
  :global(.ms-DetailsHeader) {
    background-color: #fafafa;
  }
  :global(.ms-DetailsHeader-cellName) {
    font-size: 12px;
    font-weight: 400;
  }
}
.transmissionTypeDropdown {
  width: 200px;
  margin-bottom: 20px;
}
