.filterItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 2px 0px;
}
.filterItem {
  background-color: #777;
  color: white;
  border-radius: 12px;
  padding: 4px 10px;
  height: 20px;
  font-size: 12px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  margin: 2px 5px 2px 0px;
}
.filterItemName {
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .filterType {
    font-weight: 200;
    padding-right: 3px;
  }
}
.filterItemIcon {
  font-size: 10px;
  color: #04a8e4;
  margin-left: 4px;
  padding: 2px;
}
