.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .yearDropdown,
  .monthDropdown,
  .dayDropdown {
    padding-right: 4px;
  }
  .yearDropdown {
    width: 75px;
  }
  .monthDropdown,
  .dayDropdown {
    width: 55px;
  }
  :global(.ms-Dropdown-caretDownWrapper),
  :global(.ms-Dropdown-title) {
    font-size: 12px;
    height: 28px;
    line-height: 24px;
  }
}
