.calloutPane {
  display: flex;
  flex-direction: row;
}
.dateSelection {
  padding: 10px 20px 20px 20px;
  border-bottom: 1px solid #eee;
  .label::after {
    content: ":";
  }
}
