@import "../../shared/styles/SelectedContentHeader.module.scss";

.root {
  padding: 0px 20px 10px 20px;
}
.fieldContainer {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0px;
}
.displayField {
  font-weight: 600;
}
.errorPane {
  padding: 10px 20px;
  background-color: #ffe0f0;
  border-left: 4px solid red;
  max-height: 58px;
  overflow-y: auto;
  overflow-x: hidden;
}
.noContentPane {
  padding: 10px;
  .noContentText {
    padding-bottom: 20px;
  }
}
.contentInfoPane {
  padding: 20px 10px;
}
.header {
  padding-top: 28px;
  padding-bottom: 0px;
  padding-left: 10px;
  .headerItem {
    font-size: 28px;
    .headerText {
      min-width: 80px;
    }
    .headerIcon {
      font-size: 20px;
    }
    .lastHeaderText {
      font-weight: 400;
    }
  }
  .headerTextPane.selectable:hover {
    cursor: pointer;
    color: #0078d4;
  }
  .headerKey {
    margin-top: 6px;
  }
}
.selectedFileTypePane {
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .label {
    padding-right: 8px;
  }
  .fileTypeDropdown {
    width: 200px;
  }
}
.button {
  margin-left: 10px;
}
.fixedHeightContent {
  overflow-y: auto;
}
