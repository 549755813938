@import "../commonStyles.module.scss";

.themeProvider {
  height: 100%;
}
.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.portalContent {
  height: 100px;
  flex-grow: 1;
  background-color: #fcfcfc;
}
.header {
  height: $header-height;
  min-height: $header-height;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  color: white;
  .headerContent {
    @extend .portal-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    .headerLeftPane {
      display: flex;
      flex-direction: row;
      .appLauncher {
        display: flex;
        height: $header-height;
        width: $header-height;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
      .appLauncher:hover {
        background-color: navy;
        cursor: pointer;
      }
      .name {
        display: flex;
        align-items: center;
        .chevron {
          padding: 0px 10px;
        }
      }
    }
    .headerRightPane {
      padding: 0px 10px;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-end;
      .userPhoto {
        margin-left: 4px;
        .userImage {
          margin-top: 4px;
          height: 36px;
          width: 36px;
          border-radius: 50%;
          border: 2px solid transparent;
        }
        .userImage:hover,
        .userIcon:hover {
          cursor: pointer;
          border-color: blue;
        }
        .userIcon {
          margin: 0px 4px;
          padding-right: 8px;
        }
      }
    }
  }
}
.appList {
  .appItem {
    padding: 10px 20px;
  }
  .appItem:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
.headerTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  font-size: 11px;
  .item {
    padding: 4px 6px;
    margin: 0px 4px;
    background-color: #333;
    color: white;
    border-radius: 2px;
  }
  .userTag {
    background-color: #d0d8ff;
    color: #444;
  }
}
