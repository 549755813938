@import "../../shared/styles/Search.module.scss";
@import "../../shared/styles/ContentHeader.module.scss";

.root {
  min-height: 46px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search {
  background-color: #eee;
  border-bottom: 1px solid #e0e0e0;
}
